import { useEffect, useState } from 'react';
import {
    Container,
    Box,
    Alert,
    Checkbox,
    Typography,
    Button,
    TextField
} from '@mui/material';

import {
    GridFooter,
    DataGrid,
    GridToolbarContainer,
    GridFooterContainer,
} from '@mui/x-data-grid';

import Header from '../../components/Header';
import { callApi } from '../../utils/request';

export default function ListVariables() {
    const [alert, setAlert] = useState(null);
    const [variablesFixed, setVariablesFixed] = useState([]);
    const [modifiedVariable, setModifiedVariable] = useState(null);

    useEffect(() => {
        callApi('/operateur/variablesFixed', 'GET').then((res) => {
            setVariablesFixed(res);
        }).catch((error) => {
            console.error('Error fetching variables:', error);
            setAlert({ variant: 'error', message: 'Erreur lors du chargement des variables.' });
        });
    }, []);

    const handleSave = () => {
        const rowToSave = variablesFixed.find((row) => row.name === modifiedVariable);
        
        callApi(`/admin/updateVariable`, 'POST', rowToSave)
            .then((res) => {
                if (res.alert) {
                    setAlert(res.alert);
                    if (res.alert.variant === 'success') {
                        setTimeout(() => setAlert(null), 2500);
                    }
                }
            })
        setModifiedVariable(null);
    };

    const handleValueChange = (id, newValue) => {
        setVariablesFixed((variables) =>
            variables.map((variable) =>
                variable.name === id ? { ...variable, value: newValue } : variable
            )
        );
        setModifiedVariable(id);
    };

    const columns = [
        { field: 'name', headerName: 'Nom', width: 250 },
        {
            field: 'value', headerName: 'Valeur', width: 200, renderCell: params =>
                <TextField
                    size='small' sx={{ verticalAlign: 'middle', fontSize: 10 }}
                    value={params.value}
                    onChange={(event) => handleValueChange(params.id, event.target.value)
                    }
                />
        },
        {
            field: 'anoView', headerName: 'Vue Anonyme', width: 135, renderCell: (params) => (

                <Checkbox
                    checked={params.row.anoView}
                // onChange={(event) => handleCheckboxChange(event, params.row.id)}
                />
            ),
        },
        {
            field: 'anoEdit', headerName: 'Edit Anonyme', width: 135, renderCell: (params) => (

                <Checkbox
                    checked={params.row.anoEdit}
                // onChange={(event) => handleCheckboxChange(event, params.row.id)}
                />
            ),
        },
        {
            field: 'opeView', headerName: 'Vue Opérateur', width: 135, renderCell: (params) => (

                <Checkbox
                    checked={params.row.opeView}
                // onChange={(event) => handleCheckboxChange(event, params.row.id)}
                />
            ),
        },
        {
            field: 'opeEdit', headerName: 'Edit Opérateur', width: 135, renderCell: (params) => (

                <Checkbox
                    checked={params.row.opeEdit}
                // onChange={(event) => handleCheckboxChange(event, params.row.id)}
                />
            ),
        },
        {
            field: 'save',
            headerName: 'Action',
            width: 150,
            renderCell: (params) => {
                const isModified = params.id === modifiedVariable;
                return isModified ? (
                    <Button
                        variant="outlined"
                        color="warning"
                        size="small"
                        onClick={handleSave}
                    >
                        Sauvegarder
                    </Button>
                ) : null;
            },
        },

    ];

    return (
        <Container sx={{ padding: 5 }}>
            <Typography variant="h4" align='center'>Liste des variables</Typography>
            <Box sx={{ height: 400, width: '100%' }}>
                {alert && <Alert severity={alert.variant}> {alert.message} </Alert>}
                <DataGrid
                    rows={variablesFixed}
                    columns={columns}
                    getRowId={(row) => row.name}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 10,
                            },
                        },
                    }}
                    pageSizeOptions={[10]}
                    disableRowSelectionOnClick
                    sx={{ backgroundColor: 'white' }}
                />
            </Box>
        </Container>
    )
}
