import { useEffect, useState } from 'react';
import {
  Box,
  Chip,
  Button,
  Typography,
  Switch,
  FormControlLabel,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Alert
} from '@mui/material';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  GridFooter,
  GridFooterContainer,
} from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { callApi } from '../../utils/request';
import { useSimulator } from '../../contexts/SimulatorContext';

import currency from 'currency.js';

export default function ListSimulation() {
  const [prospectSimulations, setProspectSimulations] = useState([]);
  const [hasCloture, setHasCloture] = useState(true);
  const navigate = useNavigate();
  const { setResult } = useSimulator();
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [selectedRows, setSelectedRows] = useState(null);
  const [alert, setAlert] = useState(null);

  const columns = [
    {
      field: 'created_at', headerName: 'Date de création', width: 200, valueFormatter: (v) => {
        const date = new Date(Number(v * 1000));
        return date.toLocaleDateString('fr-FR', { year: 'numeric', month: 'long', day: 'numeric' });
      },
    },
    { field: 'created_by', headerName: 'Créateur', width: 200 },
    { field: 'prenom', headerName: 'Prénom', width: 200 },
    { field: 'nom', headerName: 'Nom', width: 200 },
    { field: 'mail', headerName: 'Adresse mail', width: 300 },
    { field: 'phone', headerName: 'Téléphone', width: 200 },
    { field: 'taux_journalier_curr', headerName: 'Taux Journalier', width: 200, valueFormatter: v => currency(v, window.currency_opts).format() },
    { field: 'nb_jours_curr', headerName: 'Nombre de jours', width: 200 },
    { field: 'frais_professionnel_curr', headerName: 'Frais Professionnel', width: 200, valueFormatter: v => currency(v, window.currency_opts).format() },
    { field: 'frais_gestion_curr', headerName: 'Frais de gestion', width: 200, valueFormatter: v => `${(v * 100).toFixed(2).replace('.', ',')} %` },
    { field: 'source', headerName: 'Source', width: 200, valueFormatter: v => v ? "Externe" : "Interne" },
    {
      field: 'state',
      headerName: 'État',
      minWidth: 150,
      maxWidth: 150,
      renderCell: (params) => {
        let color, name;
        switch (params.value) {
          case 0:
            color = 'warning';
            name = 'En cours';
            break;
          case 1:
            color = 'success';
            name = 'Terminé';
            break;
          default:
            color = 'default';
        }
        return <Chip label={name} color={color} />;
      }
    }
  ];

  const handleClose = (confirmation) => {
    if (confirmation) {
      deleteProspectSimulation(selectedRows);
    }
    setDeleteConfirm(false);
  };

  function deleteProspectSimulation(id) {
    callApi(`/operateur/deleteSimulation/${id}`, 'DELETE').then((res) => {
      if (res.alert) {
        setAlert(res.alert);
        if (res.alert.variant === 'success') {
          setTimeout(() => setAlert(null), 2500);
        }
      }
    })
  }

  function FooterToolbar() {
    return (
      <GridFooterContainer sx={{ justifyContent: selectedRows ? 'space-between' : 'end' }}>
        {selectedRows ?
          <Box display="flex" gap={1}>
            <Button
              variant="outlined"
              color="error"
              size="small"
              onClick={() => setDeleteConfirm(true)}
            >
              Supprimer
            </Button>
          </Box> : null}
        <GridFooter sx={{ border: 'none' }} />
      </GridFooterContainer>
    )
  }

  function EditToolbar() {
    return (
      <GridToolbarContainer>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid>
            <Button
              color="warning"
              startIcon={<AddIcon />}
              onClick={() => navigate('/dashboard/CreateSimulation')}
            >
              Création d'une simulation
            </Button>
          </Grid>
          <Grid>
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <GridToolbarQuickFilter
                  variant="outlined"
                  placeholder="Rechercher..."
                  size="small"
                  color='warning'
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Switch
                      checked={hasCloture}
                      color="warning"
                      onChange={() => setHasCloture(!hasCloture)}
                    />
                  }
                  label="Afficher les clôturées"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </GridToolbarContainer>
    );
  }
  useEffect(() => {
    callApi('/operateur/prospectSimulations', 'GET').then((res) => {
      const filteredSimulations = hasCloture ? res : res.filter(simulation => simulation.state === 0);
      setProspectSimulations(filteredSimulations);
    });
    setResult(null);
  }, [hasCloture, setResult, alert]);


  return (
    <>
      <Typography variant="h4" align='center'>Liste des simulations</Typography>
      <Box sx={{ width: '90%', margin: '0 auto', height: '939px' }}>
        {alert && <Alert severity={alert.variant}> {alert.message} </Alert>}
        <DataGrid
          onRowDoubleClick={(e) => navigate(`/dashboard/Simulateur/${e.id}`)}
          onRowSelectionModelChange={(rowSelect) => setSelectedRows(rowSelect[0])}
          rows={prospectSimulations}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 15,
              },
            },
          }}
          pageSizeOptions={[15]}
          slots={{
            toolbar: EditToolbar, footer: FooterToolbar
          }}

          sx={{ '--DataGrid-overlayWrapper': '300px' }}
        />
      </Box>
      <Dialog
        open={deleteConfirm}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Suppression d'une simulation
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Êtes-vous sûr de bien vouloir supprimer cette simulation ? Cette action est irréversible.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)}>Annuler</Button>
          <Button onClick={() => handleClose(true)} autoFocus>
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
