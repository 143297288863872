import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Container, Alert, Typography, Box, Grid } from '@mui/material';

import PaySlip from '../components/PaySlip';
import InformationsFrais from '../components/InformationsFrais';
import Header from '../components/Header';

import { useSimulator } from '../contexts/SimulatorContext';
import { callApi } from '../utils/request';

import './Simulateur.scss';

export default function Simulateur() {
    const { id } = useParams();
    const [alert, setAlert] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const { frais, result, setResult, prospectSimulation, initial, setInitial, inputs, checkSimulation } = useSimulator();
    const [variablesFixed, setVariablesFixed] = useState([]);

    useEffect(() => {
        getVariablesFixes();
    }, []);
    
    useEffect(() => {
        if (variablesFixed) {
            prospectSimulation(id, initial, variablesFixed);
        }
    }, [variablesFixed, refresh]); 
    

    const getVariablesFixes = async () => {
        await callApi('/operateur/variablesFixed', 'GET').then((res) => {
            setVariablesFixed(res);
        });
    }

    function handlePDF() {
        callApi('/operateur/generatePaySlip', 'POST', { id, ...result }).then((res) => {
            setAlert(res.alert);

            if (res.alert.variant === "success") {
                fetch(process.env.REACT_APP_API_URL + `/public/payslip/${id}.pdf`, {
                    method: 'GET',
                    headers: {
                        'Authorization': JSON.parse(localStorage.getItem('user'))?.token
                    }
                }).then(res => res.blob())
                    .then((file) => {
                        const link = document.createElement('a');
                        link.href = window.URL.createObjectURL(file);
                        link.download = `Simulation - ${id.substring(0, 8)}.pdf`;
                        link.click();
                        link.parentNode?.removeChild(link);
                    });
            }
        });
    }

    function valueInitial() {
        setInitial(true);
        setAlert({
            variant: 'success',
            message: 'Valeurs initiales'
        });
        setTimeout(() => setAlert(null), 2500);
        setRefresh(!refresh);
    }

    function handleSimulation() {
        const errors = checkSimulation();
        if (!errors) {
            callApi('/operateur/updateSimulation', 'POST', { id, inputs, vars: frais, variablesFixed: variablesFixed }).then((res) => {
                setResult(res);
                setRefresh(!refresh);
            })
        }
    }
    function handleCloture(id) {
        callApi('/operateur/clotureSimulation', 'POST', { id: id }).then((res) => {
            setAlert(res.alert);
        });
    }

    return (
        <Container>
            <Header name="Simulateur de Fiche de Paie" />
            {alert && <Alert severity={alert.variant}>{alert.message}</Alert>}
            {!result ?
                <Typography sx={{ m: 'auto', textAlign: 'center' }}>Chargement en cours....</Typography>
                :
                <div className='content'>
                    <div className="left">
                        <InformationsFrais variablesFixed={variablesFixed} />
                        <Box display="flex" justifyContent="center" alignItems="center" sx={{ mt: 2 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Button variant="outlined" color="warning" onClick={valueInitial} fullWidth>
                                        Valeur initiale
                                    </Button>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Button variant="outlined" color="warning" onClick={handleSimulation} fullWidth>
                                        Simulation
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button variant="outlined" color="warning" onClick={handlePDF} fullWidth>
                                        Générer PDF
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button variant="outlined" color="warning" onClick={() => handleCloture(id)} fullWidth>
                                        Clôturer
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </div>
                    <div className='simulator'>
                        <PaySlip payslipVars={result} />
                    </div>
                </div>
            }
        </Container>
    );
}
